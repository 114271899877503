.stepper {

}

.stepper .MuiStepLabel-iconContainer .MuiStepIcon-root {
    color: rgba(255, 255, 255, 0.6);
}

.stepper .MuiStepLabel-iconContainer .Mui-active {
    color: white;
}

.stepper .MuiStepLabel-iconContainer .MuiStepIcon-text {
    fill: #555555;
}


.stepper .MuiStepLabel-iconContainer .Mui-completed {
    color: white;
}

.stepper .MuiStepLabel-labelContainer {
    color: rgba(255, 255, 255, 0.6);
}

.stepper .MuiStepLabel-labelContainer .MuiStepLabel-label {
    color: rgba(255, 255, 255, 0.6)
}

.stepper .MuiStepLabel-labelContainer .Mui-active {
    color: white;
}

.stepper .MuiStepLabel-labelContainer .Mui-completed {
    color: rgba(255, 255, 255, 0.8);
}