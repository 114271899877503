.richtext-container {
  color: #555555;
  font-weight: lighter;
  font-size: 0.9em;
}

.richtext-container p {
  padding: 0;
  margin: 0;
}

.richtext-container ul {
  padding-inline-start: 0;
  list-style: inside;
  padding: 0;
  margin: 0;
}

.richtext-container ol {
  padding-inline-start: 0;
  list-style: inside;
  padding: 0;
  margin: 0;
}